
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { IPublicClientApplication, PublicClientApplication, BrowserCacheLocation, InteractionType } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { MsalGuard } from '../guards/msal.guard';


const isIE =
	window.navigator.userAgent.indexOf('MSIE ') > -1 ||
	window.navigator.userAgent.indexOf('Trident/') > -1;

export function MSALInstanceFactory(): IPublicClientApplication {
	return new PublicClientApplication({
		auth: {
			clientId: environment['clientId'],
			authority: environment['tenantInfo'],
			redirectUri: environment['redirectUri'],
			postLogoutRedirectUri: environment['redirectUri'],
		},
		cache: {
			cacheLocation: BrowserCacheLocation.LocalStorage,
			storeAuthStateInCookie: isIE, // set to true for IE 11
		},
	});
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
	return {
		interactionType: InteractionType.Redirect,
		authRequest: {
			scopes: ['user.read', environment['accessApi']],
		},
		loginFailedRoute: '/not-authorized',
	};
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
	const protectedResourceMap = new Map<string, Array<string>>();
	protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', [
		'user.read',
	]);
	// protectedResourceMap.set('https://localhost:7054/api', [
	//   environment['accessApi'],
	// ]);
	protectedResourceMap.set(environment['baseUrl'], [environment['accessApi']]);

	return {
		interactionType: InteractionType.Redirect,
		protectedResourceMap,
	};
}

export const providers = [
];

export const providersWithMsal = [
	...providers,
	MsalService,
	MsalGuard,
	{ provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
	{ provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
	{ provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory },
	{ provide: MSAL_INTERCEPTOR_CONFIG, useFactory: MSALInterceptorConfigFactory }
];

