import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler } from '@angular/core';
import { GlobalErrorHandler } from './global-error-handler/global-error-handler';

import { NotifyErrorInterceptor } from './notify-error/notify-error.interceptor';
import { RequestLoaderInterceptor } from './request-loader/request-loader.interceptor';
import { RetryRequestInterceptor } from './retry-request/retry-request.interceptor';

export const InterceptorProviders = [
  { provide: ErrorHandler, useClass: GlobalErrorHandler },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RequestLoaderInterceptor,
    multi: true,
  },
  { provide: HTTP_INTERCEPTORS, useClass: NotifyErrorInterceptor, multi: true },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RetryRequestInterceptor,
    multi: true,
  },
];
