<main class="m-0">
  <div class="dark-theme">
    <div class="d-flex justify-content-between">
      <div class="d-flex p-0">
        <div class="col-auto mb-1 mt-2 pr-0">
          <img src="/assets/dark-logo.JPG" alt="think-rec-logo" class="d-flex w-76">
          <div class="tr-tag-line-mobile-view">Record it. Voice it. Share it.</div>
        </div>
        <div class="tr-tag-line">Record it. Voice it. Share it.</div>
      </div>
      <div id="column2ClonnedCustPropHeaderd69513"
        class="d-inline-flex justify-content-end align-items-center mr-3 my-2 py-1 cursor-pointer"
        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <small id="undefinedClonnedColumn2ClonnedCustPropHeader910768" class="text-white">{{userDetail?.name}}</small>
        <span class="arrow ml-3"></span>
      </div>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <span class="dropdown-item cursor-pointer" (click)="logout()">Sign Out</span>
      </div>
    </div>
  </div>
  <!-- <div class="row w-100 h-100 side-menu-background"> -->
  <!-- side nav component -->
  <!-- <app-side-nav></app-side-nav> -->
  <!-- </div> -->
  <div class="col-12 d-flex justify-content-between recordingControls" [ngClass]="!isRecordingStarted ? 'hidden' : ''">
    <div class="text-style d-flex align-items-center">
      <video id="c558a607-dc18-4444-9513-5d98e56d5e3a" autoplay #cameraVideo
        [ngClass]="recorderService?.selectedDevices?.videoDevice == '' ? 'hidden' : ''">
        Your browser does not support the video element.
      </video>
      <div id="column02efa46" class=" d-flex justify-content-between">
        <div class="recording-control-icon-container" [ngClass]="!isPaused ? 'stop-icon-active' : 'stop-icon-inactive'">
          <fa-icon id="stopIconcdfd19" class="cursor-pointer" [icon]="helper.getSolidIcon('faStop')"
            title="Stop Recording" (click)="stopRecording()"></fa-icon>
        </div>
        <div class="recording-control-icon-container pause-icon" *ngIf="!isPaused">
          <fa-icon id="pauseIcon3a84a0" class="cursor-pointer" [icon]="helper.getSolidIcon('faPause')"
            title="Pause Recording" (click)="pauseRecording()"></fa-icon>
        </div>
        <div class="recording-control-icon-container play-icon" *ngIf="isPaused">
          <fa-icon id="playIcone8667d" class="ml-1 cursor-pointer" [icon]="helper.getSolidIcon('faPlay')"
            title="Resume Recording" (click)="resumeRecording()"></fa-icon>
        </div>
        <div class="recording-control-icon-container timer ml-2 mr-4">
          <div>{{timer}}</div>
        </div>
        <div class="recording-control-icon-container restart-icon">
          <fa-icon id="restartIcone8667d" class="cursor-pointer" [icon]="helper.getSolidIcon('faArrowRotateLeft')"
            title="Restart Recording" data-toggle="modal" data-target="#restartRecordingModal"
            (click)="pauseRecording()"></fa-icon>
        </div>
        <div class="recording-control-icon-container restart-icon">
          <fa-icon id="deleteIcone8667d" class="cursor-pointer" [icon]="helper.getSolidIcon('faTrash')"
            title="Cancel Recording" data-toggle="modal" data-target="#cancelRecordingModal"
            (click)="pauseRecording()"></fa-icon>
        </div>
      </div>
    </div>
  </div>

  <div class="messages" *ngIf="isAudioSelected">
    <div class="message-item">
      <div class="d-flex align-items-center">
        <p class="mb-1 text-capitalize text-white">Your microphone is muted</p>
        <span class="vertical-line"></span>
        <fa-icon [icon]="helper.getSolidIcon('faXmark')" class="ml-auto mr-2 cursor-pointer close-button" (click)="isAudioSelected = !isAudioSelected">
        </fa-icon>
      </div>
    </div>
  </div>

  <!-- Restart Recording Confirmation Modal -->
  <div class="modal fade" id="restartRecordingModal" #restartRecordingModal tabindex="-1" role="dialog"
    data-backdrop="static" aria-labelledby="restartRecordingModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-elipsis mb-3">Restart Recording</h5>
          <fa-icon class="modal-close-icon cursor-pointer" [icon]="helper.getSolidIcon('faXmark')" data-dismiss="modal"
            (click)="resumeRecording()"></fa-icon>
        </div>
        <div class="modal-body">
          <div class="divider mb-3"> </div>
          <div class="popup-info-message mb-3">Are you sure you want to restart your recording? Your current video
            progress will be lost.</div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-modal-close" data-dismiss="modal"
            (click)="resumeRecording()">Resume</button>
          <button type="button" class="btn action-danger" id="restart-recording" data-dismiss="modal"
            (click)="restartRecording()">Restart</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Cancel Recording Confirmation Modal -->
  <div class="modal fade" id="cancelRecordingModal" #cancelRecordingModal tabindex="-1" role="dialog"
    data-backdrop="static" aria-labelledby="cancelRecordingModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-elipsis mb-3">Cancel Recording</h5>
          <fa-icon class="modal-close-icon cursor-pointer" [icon]="helper.getSolidIcon('faXmark')" data-dismiss="modal"
            (click)="resumeRecording()"></fa-icon>
        </div>
        <div class="modal-body">
          <div class="divider mb-3"> </div>
          <div class="popup-info-message mb-3">Are you sure you want to cancel your recording? Your current video
            progress
            will be lost.</div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-modal-close" data-dismiss="modal"
            (click)="resumeRecording()">Resume</button>
          <button type="button" class="btn action-danger" id="cancel-recording" data-dismiss="modal"
            (click)="cancelRecording()">Delete</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Video Preview Modal -->
  <div class="modal fade" id="videoPreviewModal" #videoPreviewModal tabindex="-1" role="dialog" data-backdrop="static"
    aria-labelledby="videoPreviewModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-elipsis mb-3">Recording Completed</h5>
          <fa-icon class="modal-close-icon cursor-pointer" [icon]="helper.getSolidIcon('faXmark')"
            (click)="save(true)"></fa-icon>
        </div>
        <div class="modal-body">
          <div class="divider mb-3"> </div>
          <form [formGroup]="saveFileForm">
            <div class="form-group">
              <label for="fileName">Video Title <span class="text-danger">*</span></label>
              <span class="popup-info-message ml-2 mr-2"><span class="info mr-1">i</span>The video will be saved automatically with the default name, if popup closed without saving</span>
              <input type="text" class="form-control" id="fileName" formControlName="fileName"
                placeholder="Enter video title" maxlength="100">
            </div>
          </form>
          <video id="videoPreview" class="w-100" controls #videoPreview controlsList="nodownload">
            Your browser does not support the video element.
          </video>
          <span *ngIf="!isRecordingUploaded" class="uploading-text">Uploading..</span>
        </div>
        <div class="modal-footer">
          <!-- <button type="button" class="btn btn-modal-close" (click)="closeModal()">Cancel</button> -->
          <button type="button" class="btn" id="save-recording" (click)="save()"
            [title]="!isRecordingUploaded ? 'Uploading the recording' : ''"
            [disabled]="saveFileForm?.invalid || !isRecordingUploaded">Save</button>
        </div>
      </div>
    </div>
  </div>
  <div class="w-100 h-100 main-content m-0 custom-height">
    <!-- router component -->
    <router-outlet></router-outlet>
  </div>
  <!-- toaster component -->
  <toaster></toaster>
</main>