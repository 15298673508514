export enum ToasterType {
  success = 0,
  warning = 1,
  error = 2,
  info = 3,
}
export class Toaster {
  constructor(
    public id: number,
    public type: ToasterType,
    public title: string,
    public message: string,
    public timeout: number,
    public clearAll: boolean
  ) {}
}
