import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  constructor() {}

  /**
   * Logging error!
   * @param log log object
   */
  logError(log: any) {
    // Send errors to server here
    console.log('LoggingService: ', log);
  }
}
