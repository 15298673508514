export const environment = {
    production: true,
    clientId: 'aaf0980f-651d-4ae6-919b-e16b7d23b1d7',
    tenantInfo:
      'https://login.microsoftonline.com/b69d82df-4ebe-474d-9ac7-00efbf13427e',
    // redirectUri: 'https://green-coast-0743c4500.4.azurestaticapps.net/',
    redirectUri: 'https://dev.recordandsharevideo.com/',
    accessApi: 'api://a3d72e81-9b65-4359-923a-5e17bdef1018/access-api',
    graphEndpoint: 'https://graph.microsoft.com/v1.0/me',
    isMsalUsed: true,
    tableStorageUrlPath: '/api/v1/azure-table/Get',
    logoUrl: 'https://thinkstack.blob.core.windows.net/demo-dynamic-actions/5a1d12be-8225-49c9-b100-08da6a53f457?sv=2021-06-08&se=9999-12-31T23%3A59%3A59Z&sr=b&sp=r&sig=Z3MdcWeUBQQTeetMD42wX88JdgNu2BmURG%2F15lofc%2BQ%3D',
    tagLine: `thinkbridge | there's a new way there`,
    copyrightLink: 'https://thinkbridge.com',
    copyrightLabel: 'thinkbridge',
    baseUrl: "https://ts-thinkrecord-devapi.azurewebsites.net"
  };
  