<div class="login-wrapper flex-column">
    <!-- <div class="container h-100 w-100 d-flex align-items-center flex-column justify-content-center">
        <img *ngIf="environment['logoUrl'] !== ''" [src]="environment['logoUrl']" alt="kavi-logo" class="mt-4 mb-4 mx-auto"
            width="mx-auto">
        <p *ngIf="environment['tagLine'] !== ''" class="mt-3 mb-3 tag-line">
            {{environment['tagLine']}}
        </p>
        <button class="btn btn-primary" (click)="login()">
            Sign in with Microsoft
        </button>
        <p *ngIf="environment['copyrightLabel'] !== '' && environment['copyrightLabel'] === ''" class="copy-right">
            <fa-icon id="faCopyright" [icon]="helper.getIcons('faCopyright')"></fa-icon>
            {{environment['copyrightLabel']}}
        </p>
        <p *ngIf="environment['copyrightLabel'] !== '' && environment['copyrightLink'] !== ''" class="copy-right">
            <fa-icon id="faCopyright" [icon]="helper.getIcons('faCopyright')"></fa-icon>
            <a [href]="environment['copyrightLink']" target="_blank">
                {{environment['copyrightLabel']}}
            </a>
        </p>
    </div> -->
    <div class="w-100 row d-flex">
        <div class="dark-theme col-md-12 col-lg-6 p-0">
            <div class="d-flex col-lg-12 p-0">
                <div class="col-auto mb-1 mt-2 pr-0">
                    <img src="/assets/dark-logo.JPG" alt="think-rec-logo" class="d-flex w-76">
                </div>
                <div class="tr-tag-line">Record it. Voice it. Share it.</div>
            </div>
        </div>
        <div class="col-lg-6 dark-light"></div>
    </div>
    <div class="row h-100 w-100 d-flex">
        <div class="col-md-12 col-lg-6 d-flex align-items-center justify-content-center flex-column">
            <!-- <div class="justify-content-start sign-in p-2">Sign in</div> -->
            <button class="btn login-btn" (click)="login()">
                Sign in with Microsoft
            </button>
            <div class="mt-5 pt-3"></div>
        </div>
        <div class="col-md-12 col-lg-6 d-flex align-items-center justify-content-center flex-column dark-light">
            <div class="row mb-2">
                <div class="col-lg-12 d-flex align-items-center justify-content-center">
                    <p class="tag-line col-md-12 col-lg-8 d-flex text-center">Helping you to go from screen recording
                        starter to collaboration hero.</p>
                </div>
            </div>
            <div class="mx-2 d-flex side-sign-in-img">
                <img src="/assets/sign-in-lap.png" alt="think-rec-img" class="d-flex sign-in-page-img" style="width: 85%;">
            </div>

            <div class="row my-3">
                <div class="col-lg-12 d-flex align-items-center justify-content-center">
                    <p class="tag-line col-md-12 col-lg-9 d-flex text-center">Take your screen recordings to the next
                        level, streamlining discussions and effortlessly sharing with your team.</p>
                </div>
            </div>


        </div>
    </div>
    <div class="d-flex align-items-center justify-content-center">
        <p *ngIf="environment['copyrightLabel'] !== '' && environment['copyrightLabel'] === ''" class="copy-right">
            <fa-icon id="faCopyright" [icon]="helper.getIcons('faCopyright')"></fa-icon>
            {{environment['copyrightLabel']}}
        </p>
        <p *ngIf="environment['copyrightLabel'] !== '' && environment['copyrightLink'] !== ''" class="copy-right">
            <fa-icon id="faCopyright" [icon]="helper.getIcons('faCopyright')"></fa-icon>
            <a [href]="environment['copyrightLink']" target="_blank">
                {{environment['copyrightLabel']}}
            </a>
        </p>
    </div>
</div>