import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MsalGuard implements CanActivate, CanLoad {

  constructor(private msalService: MsalService,
    private router: Router) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.msalService.instance.getActiveAccount() === null) {
      sessionStorage.setItem('redirectUrl', state.url);
      this.router.navigateByUrl('/login'); //redirect to login
      return false;
    }
    return true;
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let url = segments[0]?.path;
    if(segments[1]?.path) {
     url = url + '/' + segments[1].path;
    }
    if (this.msalService.instance.getActiveAccount() === null) {
      sessionStorage.setItem('redirectUrl', url);
      this.router.navigateByUrl('/login'); //redirect to login
      return false;
    }
    return true;
  }

}
