import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import { LoaderService } from 'src/app/services/loader/loader.service';

@Component({
  selector: 'loader',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit, OnDestroy {
  loader: any;
  private subscription: Subscription = new Subscription();

  constructor(private loaderService: LoaderService) {}

  /**
   * Initializing
   */
  ngOnInit() {
    this.subscription = this.loaderService.spinnerSub.subscribe((res) => {
      this.loader = res;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
