import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InterceptorProviders } from './interceptors/interceptors';
import { LoaderComponent } from './reusable/loader/loader.component';
import { ToasterComponent } from './reusable/toaster/toaster.component';
import { AgGridModule } from 'ag-grid-angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SideNavComponent } from './reusable/side-nav/side-nav.component';
import { AuthenticationComponent } from './components/authentication/authentication.component';
import { MsalModule } from '@azure/msal-angular';
import { providersWithMsal } from './services';
import { LayoutComponent } from './reusable/layout/layout.component';
@NgModule({
  declarations: [AppComponent, SideNavComponent, AuthenticationComponent, LayoutComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LoaderComponent,
    ToasterComponent,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AgGridModule,
    FontAwesomeModule,
    MsalModule
    //%%modules%%
  ],
  providers: [InterceptorProviders, providersWithMsal],
  bootstrap: [AppComponent],
})
export class AppModule {}
