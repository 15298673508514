import { Component, OnInit } from '@angular/core';
//import { CommonModule } from '@angular/common';
//import { FormsModule } from '@angular/forms';
import { ApiService } from 'src/app/services/api/api.service';
import { StateService } from 'src/app/services/state/state.service';
import { HelperService } from 'src/app/services/helper/helper.service';
//import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fa } from 'src/app/services/font-awesome/font-awesome.module';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
//standalone: true,
//imports: [CommonModule, FormsModule, FontAwesomeModule, ],
export class SideNavComponent implements OnInit {
  fa = fa;
  constructor(
    /* reference to api service */ private api: ApiService,
    /* reference to state service */ private state: StateService,
    /* reference to helper service */ public helper: HelperService
  ) {}

  ngOnInit(): void {}
}
