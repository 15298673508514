import { Component, OnInit } from '@angular/core';
import { RecorderService } from './services/recorder/recorder.service';
import { Router } from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { EventType } from '@azure/msal-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  constructor(
    public recorderService: RecorderService,
    private router: Router,
    private msalBroadcastService: MsalBroadcastService
  ) { }

  ngOnInit(): void {
    this.recorderService.getBrowserInfo();
    window.addEventListener('storage', this.localStorageUpdated);
    this.msalBroadcastService.msalSubject$.subscribe((event) => {
      if (event.eventType === EventType.LOGIN_FAILURE || event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
        // Redirect to login
        this.router.navigateByUrl('/login');
      }
    });
  }

  localStorageUpdated = (event) => {
    if (event.storageArea === localStorage && event.key === 'logged_out') {
      // Perform sign-out tasks here (navigate to login page)
      if (localStorage.getItem('logged_out') === 'true') {
        this.recorderService.cancelRecording();
        this.router.navigateByUrl('/login');
      }
    }
    if (event.storageArea === localStorage && event.key === 'logged_in') {
      // Perform sign-in tasks here (navigate to my-collection page)
      if (localStorage.getItem('logged_in') === 'true') {
        this.router.navigateByUrl('/my-collection');
      }
    }
  };

  ngOnDestroy() {
    window.removeEventListener('storage', this.localStorageUpdated);
  }
}
