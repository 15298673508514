import { Injectable } from '@angular/core';
import { StateService } from '../state/state.service';
import * as fprs from '@fortawesome/pro-regular-svg-icons';
import * as fpsolid from '@fortawesome/pro-solid-svg-icons';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  constructor(/** reference to a state service */ private state: StateService) {}

  getValues(txt: string): string {
    let val: any = this.state.getData(txt);
    if (val === undefined) val = this.getDOMValue(txt);
    if (val === undefined) val = txt?.toString()?.trim() || '';
    return val;
  }

  /**
   * Get the value of the element using received ID(DOM element ID)
   * @param id id of the DOM element
   * @returns value of the element
   */
  getDOMValue(id: string): string | undefined {
    const element: any = document.getElementById(id);
    if (element && element?.checked) return element.value;
    if (element && element?.value) return element.value;
    return undefined;
  }

  /**
   * Returns the font-awesome icon definition object
   * @param name of the font-awesome
   * @returns icon definition of the font-awesome icon
   */
  getIcons(name: string) {
    return fprs[name];
  }

  getSolidIcon(name:string){
    return fpsolid[name];
  }

  getWebUrl(href: string) {
    if (href) {
      if (href?.indexOf('http') > -1) {
        //absolute url
        return href;
      } else {
        //get url from saved obj
        return '#';
      }
    }
    return '';
  }

  getValueForParameter(form, valuePath) {
    let value;
    if (form.get(valuePath) !== null) {
      //get value from form
      value = form.get(valuePath).value;
    } else if (
      sessionStorage.getItem(valuePath) ||
      sessionStorage.getItem(valuePath?.split('[')[0]) ||
      sessionStorage.getItem(valuePath?.split('.')[0])
    ) {
      value = this.getValueFromSession(valuePath);
    } else {
      value = valuePath;
    }
    return value;
  }

  private replaceParametersWithValues(form: any, url: string): string {
    return url.replace(/{(.*?)}/g, (match, p1) => {
      // p1 is the content inside the {}, which is considered as your parameter
      return this.getValueForParameter(form, p1);
    });
  }

  getValueFromSession(valuePath: string) {
    let sessionStorageObj;
    let value;
    if (sessionStorage.getItem(valuePath)) {
      //direct object
      value = JSON.parse(JSON.stringify(sessionStorage.getItem(valuePath)));
    } else if (sessionStorage.getItem(valuePath?.split('[')[0])) {
      //inside a list of object
      sessionStorageObj = JSON.parse(sessionStorage.getItem(valuePath?.split('[')[0]));
      const matches = /\[(.*?)\]/.exec(valuePath); //get the index number between the [] identifier
      valuePath = valuePath?.slice(matches['index']);
    } else if (sessionStorage.getItem(valuePath?.split('.')[0])) {
      //inside a object via . operator
      sessionStorageObj = JSON.parse(sessionStorage.getItem(valuePath?.split('.')[0]));
      const tempPath = valuePath?.split('.');
      tempPath.shift(); //remove the identifier name at the start as we have the object in hand and only need the path that follows the identifier
      valuePath = tempPath.join('.'); //get the absolute path to access the value
    }

    if (!value) {
      value = getValue(sessionStorageObj, valuePath);
    }

    return value;
    function getValue(obj, path) {
      path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
      path = path.replace(/^\./, ''); // strip a leading dot
      const a = path.split('.');
      for (let i = 0, n = a.length; i < n; ++i) {
        const k = a[i];
        if (k in obj) {
          obj = obj[k];
        } else {
          return;
        }
      }
      return obj;
    }
  }
}
