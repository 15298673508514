import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

type State = {
  key: string;
  value: any;
};

@Injectable({ providedIn: 'root' })
export class StateService {
  // Make _stateSource private so it's not accessible from the outside,
  // expose it as state$ observable (read-only) instead.
  // Write to _stateSource only through specified store methods below.
  private readonly _stateSource = new BehaviorSubject<State[]>([]);

  // Exposed observable (read-only).
  readonly state$ = this._stateSource.asObservable();

  constructor() {}

  // Get last value without subscribing to the state$ observable (synchronously).
  getAllState(): State[] {
    return this._stateSource.getValue();
  }

  private _setState(newObj: State[]): void {
    this._stateSource.next(newObj);
  }

  addData(obj: State): void {
    const newObj = [...this.getAllState(), obj];
    this._setState(newObj);
  }

  getData(key: string): State | undefined {
    return this.getAllState().find((o) => o.key === key);
  }

  removeData(obj: State): void {
    const newObj = this.getAllState().filter((o) => o.key !== obj.key);
    this._setState(newObj);
  }
}
