import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { finalize, Observable } from 'rxjs';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class RequestLoaderInterceptor implements HttpInterceptor {
  constructor(private loaderService: LoaderService) {}
  count: number = 0;
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url !== `${environment['baseUrl']}` + '/api/v1/recording-views' && request.url !== `${environment['baseUrl']}` + '/api/v1/azure-blob/upload-chunk') {
      this.loaderService.show();
      this.count++;
    }
    return next.handle(request).pipe(finalize(() => {
      if (request.url !== `${environment['baseUrl']}` + '/api/v1/recording-views' && request.url !== `${environment['baseUrl']}` + '/api/v1/azure-blob/upload-chunk') {
        this.count--;
      }
      if (request.url === `${environment['baseUrl']}` + '/api/v1/commit-chunk') {
        this.count = 0;
      }
      if (this.count <= 0) {
        this.loaderService.hide();
      }
    }));
  }
}
