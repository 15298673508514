import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import { ToasterType, Toaster } from 'src/app/models/Toaster.model';
import { ToasterService } from 'src/app/services/toaster/toaster.service';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fa } from 'src/app/services/font-awesome/font-awesome.module';

@Component({
  selector: 'toaster',
  standalone: true,
  imports: [CommonModule, FormsModule, FontAwesomeModule],
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
})
export class ToasterComponent implements OnInit, OnDestroy {
  messagesList: Toaster[] = [];
  fa = fa;
  private subscription: Subscription = new Subscription();

  constructor(private toastr: ToasterService) {}

  ngOnInit() {
    this.subscription = this.toastr.getObservable().subscribe((notification) => {
      if (notification) {
        if (notification.clearAll) {
          this.messagesList = [];
          return;
        }
        this.addNotification(notification);
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  close(notification: Toaster) {
    this.messagesList = this.messagesList.filter((element) => element.id !== notification.id);
  }

  private addNotification(notification: Toaster) {
    this.messagesList.push(notification);
    if (notification.timeout !== 0) {
      setTimeout(() => this.close(notification), notification.timeout);
    }
  }

  getClass(notification: Toaster): string {
    switch (notification.type) {
      case ToasterType.success:
        return 'success';
      case ToasterType.warning:
        return 'warning';
      case ToasterType.error:
        return 'error';
      case ToasterType.info:
        return 'info';
      default:
        return 'default';
    }
  }

  getIcon(notification: Toaster): string {
    switch (notification.type) {
      case ToasterType.success:
        return 'solidCheckCircle';
      case ToasterType.warning:
        return 'solidTriangleExclamation';
      case ToasterType.error:
        return 'solidCircleXmark';
      case ToasterType.info:
        return 'solidCircleInfo';
      default:
        return 'default';
    }
  }
}
