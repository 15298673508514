import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Toaster, ToasterType } from 'src/app/models/Toaster.model';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  private id = 0;
  private DEFAULT_TIMEOUT = 3000;
  private subject = new Subject<Toaster>();

  getObservable(): Observable<Toaster> {
    return this.subject.asObservable();
  }

  info(message: string, title = 'Info', timeout = this.DEFAULT_TIMEOUT) {
    this.subject.next(new Toaster(this.id++, ToasterType.info, title, message, timeout, false));
  }

  success(message: string, title = 'Success', timeout = this.DEFAULT_TIMEOUT) {
    this.subject.next(new Toaster(this.id++, ToasterType.success, title, message, timeout, false));
  }

  warning(message: string, title = 'Warning', timeout = this.DEFAULT_TIMEOUT) {
    this.subject.next(new Toaster(this.id++, ToasterType.warning, title, message, timeout, false));
  }

  error(message: string, title = 'Error', timeout = this.DEFAULT_TIMEOUT) {
    this.subject.next(new Toaster(this.id++, ToasterType.error, title, message, timeout, false));
  }

  // clearAll() {
  //   this.subject.next(
  //     new Toaster(undefined, undefined, undefined, undefined, undefined, true)
  //   );
  // }
}
