import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HelperService } from 'src/app/services/helper/helper.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    /* reference to http client */ private http: HttpClient,
    /* reference to helper service */ private helper: HelperService
  ) {}

  formHeaders(headerObj: any[]) {
    const header = {};
    for (const obj of headerObj) {
      if (obj.value.includes('${')) {
        const Storedvalue = obj.value.split('${')[1].split('}')[0];
        header[obj.key] = this.helper.getValues(Storedvalue);
      } else {
        header[obj.key] = this.helper.getValues(obj.value);
      }
    }
    return header;
  }

  get(url: string, headersArray: any[]) {
    return this.http.get(url, { headers: this.formHeaders(headersArray) });
  }

  post(url: string, headersArray: any[], requestBody: any) {
    return this.http.post(url, requestBody, { headers: this.formHeaders(headersArray) });
  }

  put(url: string, headersArray: any[], requestBody: any) {
    return this.http.put(url, requestBody, { headers: this.formHeaders(headersArray) });
  }

  patch(url: string, headersArray: any[], requestBody: any) {
    return this.http.patch(url, requestBody, { headers: this.formHeaders(headersArray) });
  }

  delete(url: string, headersArray: any[]) {
    return this.http.delete(url, { headers: this.formHeaders(headersArray) });
  }
}
