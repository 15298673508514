<div>
    <div id="mySidenav" class="sidenav">
        <img src="/assets/thinkRec.JPG" alt="think-rec-logo" class="d-flex mr-auto my-3 w-75">

        <div class="menu-background">
            <ng-container>
                <a [routerLink]="'/my-collections'" type="button" class="side-menu"> 
                    <div class="">{{"My Collections"}}</div>
                </a>
            </ng-container>

        </div>
    </div>

</div>