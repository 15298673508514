import {
  faXmark as solidXmark,
  faCheckCircle as solidCheckCircle,
  faTriangleExclamation as solidTriangleExclamation,
  faCircleXmark as solidCircleXmark,
  faCircleInfo as solidCircleInfo,
} from '@fortawesome/pro-solid-svg-icons';

/**
 * Exporting all commonly used font icons as fa.
 */
export const fa: Record<string, any> = {
  solidXmark,
  solidCheckCircle,
  solidTriangleExclamation,
  solidCircleXmark,
  solidCircleInfo,
};
