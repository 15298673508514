<!-- <div class="messages">
  <div *ngFor="let item of messagesList" class="message-item" [ngClass]="getClass(item)">
    <ng-container *ngTemplateOutlet="notificationLayout;context:{message:item}"></ng-container>
  </div>
</div>

<ng-template #notificationLayout let-message="message">
  <div class="row m-0">
    <div class="col-1 p-0 d-flex justify-content-center align-items-center">
      <fa-icon [icon]="fa[getIcon(message)]"></fa-icon>
    </div>
    <div class="col-11 pl-0 pr-2 py-2">
      <div class="d-flex align-items-center">
        <h6 class="mb-1 text-capitalize text-white">{{message?.title || ''}}</h6>
        <fa-icon [icon]="fa['solidXmark']" class="ml-auto mr-2 cursor-pointer close-button" (click)="close(message)">
        </fa-icon>
      </div>
      <p class="m-0 text-break">{{message.message}}</p>
    </div>
  </div>
</ng-template> -->

<div class="messages">
  <div *ngFor="let item of messagesList" class="message-item" [ngClass]="getClass(item)">
    <ng-container *ngTemplateOutlet="notificationLayout;context:{message:item}"></ng-container>
  </div>
</div>

<ng-template #notificationLayout let-message="message">
  <div class="d-flex align-items-center">
    <p class="mb-1 text-capitalize text-white">{{message?.message || ''}}</p>
    <span class="vertical-line"></span>
    <fa-icon [icon]="fa['solidXmark']" class="ml-auto mr-2 cursor-pointer close-button" (click)="close(message)">
    </fa-icon>
  </div>
</ng-template>