import { ChangeDetectorRef, Component, OnInit, ElementRef, ViewChild, OnDestroy, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api/api.service';
import { HelperService } from 'src/app/services/helper/helper.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { RecorderService } from 'src/app/services/recorder/recorder.service';
import { ToasterService } from 'src/app/services/toaster/toaster.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {
  @ViewChild('cameraVideo') cameraVideoRef: ElementRef;
  @ViewChild('videoPreview') videoPreview: ElementRef;


  public userDetail: any;
  private subs = new Subscription();
  public isRecordingStarted: boolean;
  public isPaused: boolean = false;
  public timer: any;
  public recordedVideo: any;
  public saveFileForm!: FormGroup;
  public uploadedVideoData: any;
  public isRecordingUploaded: boolean = false;
  public isAudioSelected: boolean = false;
  public isModalOpen: boolean = false;

  constructor(private msalService: MsalService,
    public recorderService: RecorderService,
    private cdRef: ChangeDetectorRef,
    public helper: HelperService,
    private FB: FormBuilder,
    private api: ApiService,
    private toaster: ToasterService,
    private loderService: LoaderService,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    if (this.msalService.instance.getActiveAccount() !== null) {
      this.userDetail = this.msalService.instance.getActiveAccount();
    }
    this.saveFileForm = this.FB.group({
      fileName: ['', [Validators.required]],
    });
    this.subs.add(
      this.recorderService.isRecordingStarted.subscribe(isRecordingStarted => {
        this.isRecordingStarted = isRecordingStarted;
        if (this.isRecordingStarted && !this.recorderService?.selectedDevices?.audioDevice) {
          this.isAudioSelected = true;
        }
        this.cdRef.detectChanges();
      })
    );
    this.subs.add(
      this.recorderService.recordingTime.subscribe(recordingTime => {
        this.timer = this.recorderService.formatTime(recordingTime);
        this.cdRef.detectChanges();
      })
    );
    this.subs.add(
      this.recorderService.videoUrls.subscribe(videoBlob => {
        this.recordedVideo = videoBlob;
        if (this.recordedVideo) {
          this.openModal(this.recordedVideo);
          this.cdRef.detectChanges();
        }
      })
    );
    this.subs.add(
      this.recorderService.uploadedVideo.subscribe(uploadedVideo => {
        if (uploadedVideo) {
          this.uploadedVideoData = uploadedVideo;
          this.isRecordingUploaded = true;
          this.videoPreview.nativeElement.src = uploadedVideo?.url;
          if (!this.isModalOpen) {
            this.saveRecording();
          }
          this.loderService.hide();
          this.cdRef.detectChanges();
        }
      })
    );
  }

  stopRecording() {
    this.isPaused = false;
    this.isAudioSelected = false
    this.recorderService.stopRecording();
  }

  pauseRecording() {
    this.isPaused = true;
    this.recorderService.pauseRecording();
  }

  resumeRecording() {
    this.isPaused = false;
    this.recorderService.resumeRecording();
  }
  restartRecording() {
    this.isPaused = false;
    this.isAudioSelected = false
    this.recorderService.restartRecording();
  }
  cancelRecording() {
    this.isPaused = false;
    this.isAudioSelected = false
    this.recorderService.cancelRecording();
  }

  logout() {
    this.msalService.logoutRedirect();
    localStorage.setItem('logged_out', 'true');
    localStorage.removeItem('logged_in');
  }

  // Open the modal
  openModal(videoBlob) {
    this.isAudioSelected = false
    this.isModalOpen = true;
    const videoPreviewModal = document.getElementById('videoPreviewModal') as HTMLElement;
    // videoPreviewModal.classList.add('show');
    // videoPreviewModal.style.display = 'block';
    this.renderer.addClass(videoPreviewModal, 'show');
    this.renderer.setStyle(videoPreviewModal, 'display', 'block');

    // let url = URL.createObjectURL(videoBlob); //this url can be used to play video on the UI
    // let videoPreview = document.getElementById('videoPreview') as HTMLVideoElement;
    // videoPreview.src = url;
  }

  // Close the modal
  closeModal() {
    this.isModalOpen = false;
    const videoPreviewModal = document.getElementById('videoPreviewModal') as HTMLElement;
    videoPreviewModal.classList.remove('show');
    videoPreviewModal.style.display = 'none';
    let videoPreview = document.getElementById('videoPreview') as HTMLVideoElement;
    videoPreview.src = ''
    this.recorderService.videoUrlSource.next(null);
  }

  save(isClose?: boolean) {
    if (isClose) {
      this.saveFileForm.reset();
    }
    if (this.isRecordingUploaded) {
      this.saveRecording();
    } else {
      this.closeModal();
    }
  }

  saveRecording() {
    const fileName = this.saveFileForm.value.fileName ? this.saveFileForm.value.fileName : this.uploadedVideoData?.title;
    this.api.post(`${environment['baseUrl']}` + '/api/v1/recordings', [], {
      "title": fileName,
      "url": this.uploadedVideoData?.url,
      "azureFileName": this.uploadedVideoData?.azureFileName,
      "oid": this.uploadedVideoData?.oid,
      "isUploaded": this.uploadedVideoData?.isUploaded,
      "duration": this.uploadedVideoData?.duration,
      "azureContainerName": this.uploadedVideoData?.azureContainerName
    }).subscribe({
      next: (res: any) => {
        this.toaster.success('Recording saved succesfully');
        this.recorderService.isRecordingSavedSub.next(true);
        this.closeModal();
        this.saveFileForm.reset();
        this.uploadedVideoData = null;
        this.isRecordingUploaded = false;
      }, error: (error: any) => {
        this.toaster.error(error?.error?.messages[0].message);
      }
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
