import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { ToasterService } from 'src/app/services/toaster/toaster.service';

@Injectable()
export class NotifyErrorInterceptor implements HttpInterceptor {
  constructor(private toastr: ToasterService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // refresh token
          if (!request.url.includes('LogUnexpectedBehavior'))
            this.toastr.warning('Access restricted.', 'Unauthorised!');
        }
        return throwError(error);
      })
    );
  }
}
