import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  /**
   * Default spinner obj with default values
   */
  spinner = {
    loader: false,
    message: 'Loading...',
  };
  /** spinner subject */
  spinnerSub = new BehaviorSubject(this.spinner);

  constructor() {}

  /**
   * Show loader with optional text passed!
   * @param msg loader text
   */
  show = (msg?: string) => {
    this.spinner.loader = true;
    if (msg) this.spinner.message = msg;
    this.spinnerSub.next(this.spinner);
  };

  /**
   * Hide active loader!
   * change the loader text to default text 'Loading...'
   */
  hide = () => {
    this.spinner.loader = false;
    this.spinner.message = 'Loading...';
    this.spinnerSub.next(this.spinner);
  };
}
