import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  getClientErrorMessage(error: Error) {
    return {
      message: error?.name || error?.message || 'Something went wrong!',
      description: (error?.message || '') + (error?.stack || ''),
      error,
    };
  }

  getServerErrorMessage(error: HttpErrorResponse) {
    const description =
      (error?.url || '') + (error?.statusText || '') + (error?.message || '') + JSON.stringify(error?.error || '');
    return navigator.onLine
      ? {
          message: error?.name || error?.message || error?.url,
          description,
          error,
        }
      : {
          message: 'No Internet Connection',
          description,
        };
  }
}
