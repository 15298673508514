import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LoaderService } from '../loader/loader.service';
import { ToasterService } from '../toaster/toaster.service';
import { ApiService } from '../api/api.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
@Injectable({
  providedIn: 'root',
})
export class RecorderService {
  public displayStream: any;
  public videoStream: any;
  public audioStream: any;
  public mediaRecorder: any;
  public recordedChunks = [];
  public blockIds = [];
  private isFinalChunkUploaded: boolean = false;
  private fileName: string = 'thinkRecord';
  private type: string;
  private format: string;
  defaultAudioOption = { deviceId: '', deviceName: 'No Microphone' }
  defaultVideoOption = { deviceId: '', deviceName: 'No Camera' }
  private isRestart: boolean = false;
  private isCancel: boolean = false;
  private cameraVideoRef: any;
  public selectedDevices: any;

  public browserName: any;
  private containerName: string = 'thinkRecord';
  private loggedInUserData: any;

  private audioMediaSource = new BehaviorSubject<{ deviceId: string, deviceName: string }[]>([this.defaultAudioOption]);
  audioMedia = this.audioMediaSource.asObservable();
  private videoMediaSource = new BehaviorSubject<{ deviceId: string, deviceName: string }[]>([this.defaultVideoOption]);
  videoMedia = this.videoMediaSource.asObservable();
  public videoUrlSource = new BehaviorSubject<any>(null);
  videoUrls = this.videoUrlSource.asObservable();
  private isRecordingStarted1 = new BehaviorSubject<any>(false);
  isRecordingStarted = this.isRecordingStarted1.asObservable();
  private recordingTimer = new BehaviorSubject<number>(0);
  recordingTime = this.recordingTimer.asObservable();
  private uploadedVideoData = new BehaviorSubject<any>(null);
  uploadedVideo = this.uploadedVideoData.asObservable();
  public isRecordingSavedSub = new BehaviorSubject<any>(false);
  isRecordingSaved = this.isRecordingSavedSub.asObservable();
  public isAudioDeviceErrorSub = new BehaviorSubject<any>(false);
  isAudioDeviceError = this.isAudioDeviceErrorSub.asObservable();
  public isVideoDeviceErrorSub = new BehaviorSubject<any>(false);
  isVideoDeviceError = this.isVideoDeviceErrorSub.asObservable();
  private interval;
  public time: number;
  private uploadCounter: number = 0; // A counter to keep track of ongoing uploads
  private datePipe = new DatePipe('en-US');

  private audioDevices: any = [];
  private videoDevices = [];
  private deviceLabels = [];

  constructor(private loaderService: LoaderService,
    private toaster: ToasterService,
    private api: ApiService,
  ) { }


  //method to start recording
  startRecording(selectedDevices?) {
    this.selectedDevices = selectedDevices;
    navigator.mediaDevices.getDisplayMedia({ audio: selectedDevices?.audioDevice !== '', video: true }).then((displayStream) => {
      this.displayStream = displayStream;
      let videoPromise = selectedDevices?.videoDevice !== '' ? navigator.mediaDevices.getUserMedia({ video: { deviceId: selectedDevices?.videoDevice } }) : Promise.resolve(null);
      let audioPromise = selectedDevices?.audioDevice !== '' ? navigator.mediaDevices.getUserMedia({ audio: { deviceId: selectedDevices?.audioDevice } }) : Promise.resolve(null);

      audioPromise.then((audioStream) => {
        this.audioStream = audioStream;
        let combinedStream = new MediaStream([...displayStream.getVideoTracks(), ...(this.audioStream ? this.audioStream.getAudioTracks() : [])]);

        // Add event handler to stop recording when display stream ends
        this.displayStream.getTracks().forEach((track) => {
          track.onended = () => {
            if (this.mediaRecorder.state === 'recording') {
              this.mediaRecorder.stop();
            }
          };
        });
        let options = {};
        if (MediaRecorder.isTypeSupported('video/webm; codecs=vp8,opus') && audioStream) {
          options = { mimeType: 'video/webm; codecs=vp8,opus' };
          this.type = 'video/webm';
          this.format = '.webm';
        } else if (MediaRecorder.isTypeSupported('video/webm; codecs=vp8,opus') && !audioStream) {
          options = { mimeType: 'video/webm; codecs=vp8' };
          this.type = 'video/webm';
          this.format = '.webm';
        } else if (MediaRecorder.isTypeSupported('video/mp4; codecs="avc1.42E01E, mp4a.40.2"')) {
          options = { mimeType: 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"' };
          this.type = 'video/mp4';
          this.format = '.mp4';
        }
        this.mediaRecorder = new MediaRecorder(combinedStream, options);
        this.mediaRecorder.ondataavailable = (event) => this.handleDataAvailable(event); //method to be call when recording data is available (either when recording is stopped or time frame passed to mediaRecorder.start() )
        this.mediaRecorder.onstop = () => this.handleStop(); //method to be call when recording is stopped

        //start countdown here
        this.mediaRecorder.start(5000); //start screen recording: call this function after countdown is completed 
        this.resetOnStart();
      }).catch((error) => {
        // Handle audio promise error
      });
      this.startCamera(videoPromise)

      // Promise.all([audioPromise, videoPromise]).then((streams) => {
      //   this.audioStream = streams[0];
      //   this.videoStream = streams[1];
      //   // if (this.videoStream) {
      //   //   this.cameraVideoRef.srcObject = this.videoStream;
      //   // }

      //   let combinedStream = new MediaStream([...displayStream.getVideoTracks(), ...(this.audioStream ? this.audioStream.getAudioTracks() : [])]);

      //   // Add event handler to stop recording when display stream ends
      //   this.displayStream.getTracks().forEach((track) => {
      //     track.onended = () => {
      //       if (this.mediaRecorder.state === 'recording') {
      //         this.mediaRecorder.stop();
      //       }
      //     };
      //   });
      //   let options = {};
      //   if (MediaRecorder.isTypeSupported('video/mp4; codecs="avc1.42E01E, mp4a.40.2"')) {
      //     options = { mimeType: 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"' };
      //   }
      //   if (MediaRecorder.isTypeSupported('video/webm; codecs=vp8,opus')) {
      //     options = { mimeType: 'video/webm; codecs=vp8,opus' };
      //   }
      //   this.mediaRecorder = new MediaRecorder(combinedStream, options);
      //   this.mediaRecorder.ondataavailable = (event) => this.handleDataAvailable(event); //method to be call when recording data is available (either when recording is stopped or time frame passed to mediaRecorder.start() )
      //   this.mediaRecorder.onstop = () => this.handleStop(); //method to be call when recording is stopped

      //   //start countdown here
      //   this.mediaRecorder.start(5000); //start screen recording: call this function after countdown is completed 
      //   this.resetOnStart();
      // });

    });
  }
  startCamera(videoPromise: any) {
    this.cameraVideoRef = document.getElementById('c558a607-dc18-4444-9513-5d98e56d5e3a') as HTMLVideoElement;;
    videoPromise.then((videoStream) => {
      this.videoStream = videoStream;
      if (this.videoStream) {
        this.cameraVideoRef.srcObject = this.videoStream;
      }
    }).catch((error) => {
      // Handle video promise error
    });
  }
  resetOnStart() {
    this.uploadedVideoData.next(null);
    this.isFinalChunkUploaded = false;
    this.blockIds = [];
    let date = new Date();
    let timestamp = date.getTime();
    // let format = this.browserName == 'Safari' ? '.mp4' : '.webm';
    this.fileName = `thinkRecord ${timestamp}` + this.format;

    this.isRecordingStarted1.next(true);
    this.time = 0; //give any time (in sec) if need to restrict the recording time and update in startTimer() accordingly
    this.startTimer();
    this.uploadCounter = 0;
  }
  //method to stop recording
  stopRecording() {
    this.mediaRecorder.stop();
  }
  //method to pause recording
  pauseRecording() {
    //clear timer 
    clearInterval(this.interval);
    this.mediaRecorder.pause();
  }
  //method to resume recording
  resumeRecording() {
    this.mediaRecorder.resume();
    this.startTimer();
  }
  //method to restart recording
  restartRecording() {
    this.isRestart = true;
    this.stopRecording();
    // setTimeout(()=> this.startRecording(this.selectedDevices), 1000 ); // restarting after 1 second
  }
  //method to cancel recording
  cancelRecording() {
    this.isCancel = true;
    if (this.mediaRecorder?.state === 'paused' || this.mediaRecorder?.state === 'recording') {
      this.stopRecording();
    }
  }
  // method to handle recorded data
  handleDataAvailable(event) {
    if (event.data && event.data.size > 0) {
      this.recordedChunks.push(event.data);
      const blockId = btoa(String(this.recordedChunks.length - 1).padStart(5, '0'));
      this.blockIds.push(blockId);

      let isDeleteBlob = this.isCancel || this.isRestart ? true : false;
      if (this.mediaRecorder.state === 'inactive' && !this.isFinalChunkUploaded) {
        this.isFinalChunkUploaded = true; // Set the flag to true if this is the final chunk
        this.uploadBlob(event.data, blockId, this.fileName, isDeleteBlob);
      } else {
        this.uploadBlob(event.data, blockId, this.fileName, isDeleteBlob); // false indicates not the last chunk
      }
    }
  }
  //method to handle stop operation
  handleStop() {
    //clear timer 
    clearInterval(this.interval);

    this.isRecordingStarted1.next(false);
    //stop streams
    if (this.displayStream) {
      this.displayStream.getTracks().forEach(track => track.stop());
    }
    if (this.videoStream) {
      this.videoStream.getTracks().forEach(track => track.stop());
    }
    if (this.audioStream) {
      this.audioStream.getTracks().forEach(track => track.stop());
    }

    // Check if the final chunk must be uploaded (e.g., if the ondataavailable was not triggered for the last piece of data)
    if (this.recordedChunks.length && !this.isFinalChunkUploaded) {
      this.isFinalChunkUploaded = true;
      // let type = this.browserName == 'Safari' ? 'video/mp4' : 'video/webm';

      const finalChunk = new Blob(this.recordedChunks, { type: this.type });
      const blockId = btoa(String(this.recordedChunks.length - 1).padStart(5, '0'));
      this.blockIds.push(blockId);
      let isDeleteBlob = this.isCancel || this.isRestart ? true : false;
      this.uploadBlob(finalChunk, blockId, this.fileName, isDeleteBlob);
    }
    //for generating blob of recorded video
    this.generateBlob();
  }
  //method to generating blob of recorded video
  generateBlob() {
    if (this.isRestart) {
      this.isRestart = false;
      this.startRecording(this.selectedDevices); // safari browser its not triggering hence moved to restart method
    } else if (this.isCancel) {
      this.isCancel = false;
      this.cameraVideoRef = null;
      this.selectedDevices = null;
    } else {
      // let type = this.browserName == 'Safari' ? 'video/mp4' : 'video/webm';
      let blob = new Blob(this.recordedChunks, {
        type: this.type
      });
      this.updateRecordedVideos(blob);
      this.cameraVideoRef = null;
      this.selectedDevices = null;
    }
    this.recordedChunks = [];

    // //code to download recorded video start
    // let a = document.createElement('a');
    // document.body.appendChild(a);
    // a.href = url;
    // a.download = 'test.webm';
    // a.click();
    //code to download recorded video end
  }

  getUserDevices() {
    this.loaderService.show();
    this.audioDevices = [];
    this.videoDevices = [];
    this.deviceLabels = [];

    this.audioDevices.push(this.defaultAudioOption);
    this.videoDevices.push(this.defaultVideoOption);

    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      stream.getTracks().forEach(track => track.stop());
      navigator.mediaDevices.enumerateDevices().then(deviceInfos => {
        const audioDevices = deviceInfos.filter(device => device.kind === 'audioinput');
        this.handleDevices(audioDevices)
      });
    }).catch((error) => {
      this.isAudioDeviceErrorSub.next(true);
      console.log('getUserDevices', 'error in accessing audio devices');
      console.log(error);
      // this.loaderService.hide();
    });
    navigator.mediaDevices.getUserMedia({ video: true }).then((stream) => {
      stream.getTracks().forEach(track => track.stop());
      navigator.mediaDevices.enumerateDevices().then(deviceInfos => {
        const videoDevices = deviceInfos.filter(device => device.kind === 'videoinput');
        this.handleDevices(videoDevices, true)
      });
    }).catch((error) => {
      this.isVideoDeviceErrorSub.next(true);
      console.log('getUserDevices', 'error in accessing video devices');
      console.log(error);
      this.loaderService.hide();
      console.log('getUserDevices');
      console.log(error);
      // alert(error.message + "\nPlease allow media devices usage in your browser settings for this website.")
    });
  }
  handleDevices(deviceInfos, isVideoDevices?) {
    deviceInfos.forEach((deviceInfo) => {
      if ((deviceInfo.kind == 'audioinput' || deviceInfo.kind == 'videoinput') && !deviceInfo.label.startsWith('Communications')) {
        let cleanDeviceLabel = deviceInfo.label.replace('Default - ', '');

        let device = { deviceId: deviceInfo.deviceId, deviceName: deviceInfo.label || 'Unknown Device' }
        if (deviceInfo.kind == 'audioinput' && !this.deviceLabels.includes(cleanDeviceLabel)) {
          this.deviceLabels.push(cleanDeviceLabel);
          this.audioDevices.push(device);

        } else if (deviceInfo.kind == 'videoinput' && !this.deviceLabels.includes(cleanDeviceLabel)) {
          this.deviceLabels.push(cleanDeviceLabel);
          this.videoDevices.push(device);
        }
      }
    });
    this.audioMediaSource.next(this.audioDevices);
    this.videoMediaSource.next(this.videoDevices);
    if (isVideoDevices) {
      this.loaderService.hide();
    }
  }

  updateRecordedVideos(newVideoUrl) {
    this.videoUrlSource.next(newVideoUrl);
  }

  startTimer() {
    this.recordingTimer.next(this.time);
    this.interval = setInterval(() => {
      this.time++; //if restriction is added then change it to "this.time--""
      this.recordingTimer.next(this.time);

      //uncomment if restriction is added
      // if (this.time == 0) {
      //   this.mediaRecorder.stop();
      // }
    }, 1000);
  }

  // copyLink(videoId) {
  //   let selBox = document.createElement('textarea');
  //   selBox.value = window.location.protocol + "//" + window.location.host + "/play-video/" + videoId;
  //   document.body.appendChild(selBox);
  //   // selBox.focus();
  //   selBox.select();
  //   document.execCommand('copy');
  //   document.body.removeChild(selBox);
  //   this.toaster.success('Copied successfully!');
  // } 

  //Method to copy the link (play-video page url with video id)
  copyLink(videoId: any) {
    const url = window.location.protocol + "//" + window.location.host + "/play-video/" + videoId;
    if (navigator.clipboard && window.isSecureContext) {
      // If Clipboard is available and we're in a secure context
      navigator.clipboard.writeText(url).then(() => {
        this.toaster.success('Copied successfully!');
      }).catch(err => {
        this.toaster.error('Failed to copy!');
        console.error('Failed to copy', err);
      });
    } else {
      // Fallback method, in case Clipboard API not supported
      let selBox = document.createElement('textarea');
      selBox.value = url;
      document.body.appendChild(selBox);
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
      this.toaster.success('Copied successfully!');
    }
  }

  getBrowserInfo() {
    const ua = navigator.userAgent;
    let browserName, fullVersion, majorVersion;

    // Check if it's one of the common browsers
    if (ua.indexOf("Firefox") !== -1) {
      browserName = "Firefox";
    } else if (ua.indexOf("OPR/") !== -1) {
      browserName = "Opera";
    } else if (ua.indexOf("Edg") !== -1) {
      browserName = "Edge";
    } else if (ua.indexOf("Chrome") !== -1) {
      browserName = "Chrome";
    } else if (ua.indexOf("Safari") !== -1) {
      browserName = "Safari";
    } else if (ua.indexOf("MSIE") !== -1 || ua.indexOf("Trident/") !== -1) {
      browserName = "Internet Explorer";
    } else {
      browserName = "Unknown";
    }
    // Try to extract version number
    // fullVersion = ''+parseFloat(navigator.appVersion); 
    // majorVersion = parseInt(navigator.appVersion,10);
    // let temp, ix;

    // if ((ix = ua.indexOf(browserName)) !== -1) {
    //   fullVersion = ua.substring(ix + browserName.length + 1);
    //   if ((ix = fullVersion.indexOf(';')) !== -1)
    //     fullVersion = fullVersion.substring(0, ix);
    //   if ((ix = fullVersion.indexOf(' ')) !== -1)
    //     fullVersion = fullVersion.substring(0, ix);
    //   majorVersion = parseInt('' + fullVersion, 10);
    //   if (isNaN(majorVersion)) {
    //     fullVersion = '' + parseFloat(navigator.appVersion);
    //     majorVersion = parseInt(navigator.appVersion, 10);
    //   }
    // }

    this.browserName = browserName;
  }

  uploadBlob(blob: Blob, blockId: string, fileName: string, isDeleteBlob: boolean) {
    this.uploadCounter++;
    const formData = new FormData();
    formData.append('FileName', fileName);
    formData.append('Chunk', blob);
    formData.append('BlockId', blockId);
    formData.append('StorageContainer', this.containerName);
    formData.append("IsPublic", 'true');
    formData.append("IsDeleteBlob", `${isDeleteBlob}`);
    this.api.post(`${environment['baseUrl']}` + '/api/v1/azure-blob/upload-chunk', [], formData).subscribe({
      next: (res: any) => {
        this.uploadCounter--;
        if (!isDeleteBlob && this.uploadCounter === 0 && this.isFinalChunkUploaded) {
          // this.loaderService.show();
          // proceed to commit the blob when all chunks are uploaded
          this.commitBlocks(fileName, this.blockIds);
        }
      }, error: (error: any) => {
        this.toaster.error(error?.error?.messages[0].message);
      }
    });
  }

  commitBlocks(fileName: string, blockIds: string[]) {
    this.api.post(`${environment['baseUrl']}` + '/api/v1/azure-blob/commit-chunk', [], {
      "FileName": fileName,
      "BlockIds": blockIds,
      "StorageContainer": this.containerName
    }).subscribe({
      next: (res: any) => {
        let formatedTimestamp = this.datePipe.transform(new Date(), 'EEE, MMM dd yyyy');
        let fileName = `thinkRecord ${formatedTimestamp}`;
        let response = {
          "title": fileName,
          "url": res?.fileSasUrl,
          "azureFileName": res?.fileName,
          "oid": this.loggedInUserData?.oid,
          "isUploaded": false,
          "duration": this.time,
          "azureContainerName": this.containerName
        }
        this.uploadedVideoData.next(response);
      }, error: (error: any) => {
        this.toaster.error(error?.error?.messages[0].message);
      }
    });
  }

  //Method to set azure blob storage container name
  setContainerName(loggedInUserData: any) {
    this.loggedInUserData = loggedInUserData;
    let containerMaxLength = 63;
    this.containerName = loggedInUserData?.firstName?.replace(/\s+/g, '').toLowerCase() + '-' + loggedInUserData?.id.toLowerCase();
    if (this.containerName.length > containerMaxLength) {
      this.containerName = loggedInUserData?.firstName?.split(' ')[0].toLowerCase() + '-' + loggedInUserData?.id.toLowerCase();
    }
    return this.containerName;
  }

  //method to format second to MM:SS format
  formatTime(time: number): string {
    if (isNaN(time)) {
      return time.toString();
    }
    const minutes = Math.floor(time / 60);
    const seconds = Math.round(time - minutes * 60);

    const minutesStr = String(minutes).padStart(2, '0');
    const secondsStr = String(seconds).padStart(2, '0');

    return `${minutesStr}:${secondsStr}`;
  }
}
