import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from './guards/msal.guard';
import { AuthenticationComponent } from './components/authentication/authentication.component';
import { LayoutComponent } from './reusable/layout/layout.component';

const routes: Routes = [
  { path: 'login', component: AuthenticationComponent },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: '', component:LayoutComponent,
    canActivate: [MsalGuard],
    canLoad: [MsalGuard],
    loadChildren: () => import('./modules/recorder/recorder.module').then((m) => m.RecorderModule)
  },
 
  { path: '**', pathMatch: 'full', redirectTo: 'my-collections' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
